import { DetailedHTMLProps, SelectHTMLAttributes } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { cx } from '@emotion/css';

interface Props {
  name: string;
  label: string;
  options: Array<any>;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  onNext?: () => void;
  styles: any;
}
export const SelectInput = (
  props: Props &
    DetailedHTMLProps<
      SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
) => {
  const { control, setValue } = useFormContext();
  const { name, label, options, rules, onNext, styles, ...otherProps } = props;

  const onChange = (e: any) => {
    setValue(name, e.target.value);
    if (onNext) {
      onNext();
    }
  };
  return (
    <>
      <label className={styles.f_label}>{label}</label>
      <Controller
        render={({ field }) => {
          return (
            <div className={cx(styles.f_in, styles.f_select)}>
              <select
                className={styles.form_control}
                {...field}
                {...otherProps}
                onChange={onChange}>
                {options.map((option) => (
                  <option value={JSON.stringify(option)} key={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          );
        }}
        control={control}
        name={name}
        rules={rules}
      />
    </>
  );
};
