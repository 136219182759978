import * as yup from 'yup';

// import { nameofFactory } from '@common/util/nameofFactory';
import { nameofFactory } from '@common/util/nameofFactory';
import { Job } from '@controller/schema';

import {
  COMMON_ERR_MESSAGES,
  emailValidation,
  objectValidation,
  requiredValidation,
} from './common';

export const CLIENT_FORM = {
  CITY: 'city',
  PROBLEM_CATEGORY_ID: nameofFactory<Job>()('problemCategoryId'),
  PROBLEM_SUB_CATEGORY_ID: nameofFactory<Job>()('problemSubcategoryId'),
  SKILL_LIST: nameofFactory<Job>()('skillList'),
  CLIENT_COST: nameofFactory<Job>()('clientCost'),
  DATE_AND_TIME: 'dateAndTime',
  DESCRIPTION: nameofFactory<Job>()('details'),
  IMAGES: nameofFactory<Job>()('images'),
  VIDEOS: nameofFactory<Job>()('videos'),
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ADDRESS: 'address',
};

export const flowSchema = yup.object().shape({
  [CLIENT_FORM.CITY]: objectValidation(CLIENT_FORM.CITY),
  [CLIENT_FORM.PROBLEM_CATEGORY_ID]: requiredValidation(
    CLIENT_FORM.PROBLEM_CATEGORY_ID,
  ),
  [CLIENT_FORM.PROBLEM_SUB_CATEGORY_ID]: requiredValidation(
    CLIENT_FORM.PROBLEM_SUB_CATEGORY_ID,
  ),
  [CLIENT_FORM.DATE_AND_TIME]: yup
    .array()
    .min(1, 'Select at least one date and time slot')
    .required(),
  // [CLIENT_FORM.TITLE]: requiredValidation(CLIENT_FORM.TITLE),
  [CLIENT_FORM.DESCRIPTION]: requiredValidation(CLIENT_FORM.DESCRIPTION),
  [CLIENT_FORM.FULL_NAME]: requiredValidation('Full Name'),
  [CLIENT_FORM.EMAIL]: emailValidation,

  [CLIENT_FORM.PHONE_NUMBER]: requiredValidation('Contact Number'),
  [CLIENT_FORM.ADDRESS]: requiredValidation('Address'),
});

export const partialJobUpdateFlowSchema = yup.object().shape({
  [CLIENT_FORM.CITY]: requiredValidation(CLIENT_FORM.CITY),
  [CLIENT_FORM.PROBLEM_CATEGORY_ID]: requiredValidation(
    CLIENT_FORM.PROBLEM_CATEGORY_ID,
  ),
  [CLIENT_FORM.PROBLEM_SUB_CATEGORY_ID]: requiredValidation(
    CLIENT_FORM.PROBLEM_SUB_CATEGORY_ID,
  ),
  [CLIENT_FORM.EMAIL]: yup
    .string()
    .email(COMMON_ERR_MESSAGES.INVALID_EMAIL_FORMAT),
});
