import React from 'react';

import { SelectOptionProps } from '@common/constants';
import { CLIENT_FORM } from '@common/schema/flow';
import { useSearchCityListQuery } from '@controller/schema';
import { cx } from '@emotion/css';
import { SelectInput } from '@modules/shared/SelectInput';

interface Props {
  styles: any;
  defaultValue?: any | undefined | null;
}

const fieldName = CLIENT_FORM.CITY;
export const SelectCity = (props: Props) => {
  const { styles, defaultValue } = props;
  const { data, isLoading } = useSearchCityListQuery(
    {},
    { cacheTime: 1000 * 60 * 10 },
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const cityList = data?.searchCityList.cityList;

  const cityListOptions: SelectOptionProps[] | undefined = cityList?.map(
    (city) => ({
      id: city.id,
      label: city.city,
    }),
  );
  cityListOptions?.unshift({ label: 'Select City' });
  return (
    <div className={cx(styles.f_wrpr, styles.active)} style={{ width: '100%' }}>
      <SelectInput
        label=""
        name={fieldName}
        options={cityListOptions ?? []}
        styles={styles}
        disabled={!!defaultValue}
      />
    </div>
  );
};
